<template>
  <div class="container-fluid page-body-wrapper full-page-wrapper">
    <div class="content-wrapper d-flex align-items-center auth">
      <div class="row flex-grow">
        <div class="col-lg-4 mx-auto">
          <div class="auth-form-light text-left p-5">
            <div class="brand-logo">
              <img src="" />
              <h1>MCard</h1>
            </div>
            <h4>Change Your Password</h4>
            <form id="form" class="pt-3" @submit.prevent="onSubmit">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-lg"
                  id="otp"
                  placeholder="Enter OTP"
                  v-model="otp"
                />
              </div>
              <div class="form-group">
                <input
                  type="password"
                  class="form-control form-control-lg"
                  id="Password"
                  placeholder="Enter Password"
                  v-model="password"
                />
              </div>
              <div class="mt-3">
                <button
                  class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"
                  v-on:click="changePassword"
                  :disabled="loading"
                >
                  <div
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                  ></div>
                  <span v-if="loading">Processing Request</span>
                  <span v-else>Change Password</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { useToast } from "vue-toastification";
import { HTTP } from "../http-common";
const toast = useToast();
export default {
  name: "changePassword",
  data() {
    return {
      email_code: "",
      phone_code: "",
      loading: false,
    };
  },
  methods: {
    async changePassword() {
      this.loading = true;
      let user = localStorage.getItem("user-email");
      let user_data = JSON.parse(user);

      console.log(user_data["email"]);
      const postData = {
        email: user_data["email"],
        code: this.otp,
        new_password: this.password,
      };
      const headers = {
        headers: { "Content-Type": "application/json; charset=UTF-8" },
      };
      await HTTP.post("auth/change-password", postData, headers)
        .then(
          (response) => (
            (this.loading = false),
            console.log(response.data.message),
            toast.success(response.data.message, {
              timeout: 2000,
            }),
            this.$router.push({ name: "Login" })
          )
        )
        .catch(
          (error) => (
            (this.loading = false),
            console.log(error),
            console.log(error.response.data.message),
            // or with options
            toast.error(error.response.data.message, {
              timeout: 5000,
            })
          )
        );
    },
  },
};
</script>

<script>
</script>