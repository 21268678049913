<template>
  <div class="container-fluid page-body-wrapper full-page-wrapper">
    <div class="content-wrapper d-flex align-items-center auth">
      <div class="row flex-grow">
        <div class="col-lg-4 mx-auto">
          <div class="auth-form-light text-left p-5">
            <div class="brand-logo">
              <img src="" />
              <h1>MCard</h1>
            </div>
            <h4>
              Verify OTP, to complete signup
              <br />
              (Enter any 6 digit number we are not checking the OTP)
            </h4>
            <form class="pt-3" @submit.prevent="onSubmit">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-lg"
                  id="phone_code"
                  placeholder="Enter Phone OTP"
                  v-model="phone_code"
                />
              </div>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-lg"
                  id="email_code"
                  placeholder="Enter Email OTP"
                  v-model="email_code"
                />
              </div>
              <div class="mt-3">
                <a
                  class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"
                  v-on:click="verifyOtp"
                  >Verify OTP</a
                >
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { useToast } from "vue-toastification";
import { HTTP } from "../http-common";
const toast = useToast();
export default {
  name: "OTP",
  data() {
    return {
      email_code: "",
      phone_code: "",
    };
  },
  methods: {
    async verifyOtp() {
      let user = localStorage.getItem("user-info");
      let user_data = JSON.parse(user);

      console.log(user_data["user_id"]);
      const postData = {
        user_id: user_data["user_id"],
        email_code: this.email_code,
        phone_code: this.phone_code,
      };
      const headers = {
        headers: { "Content-Type": "application/json; charset=UTF-8" },
      };
      await HTTP.post("auth/verify-account", postData, headers)
        .then(
          (response) => (
            console.log(response.data.message),
            toast.success(response.data.message, {
              timeout: 2000,
            }),
            this.$router.push({ name: "OnBoarding" })
          )
        )
        .catch(
          (error) => (
            console.log(error),
            console.log(error.response.data.message),
            // or with options
            toast.error(error.response.data.message, {
              timeout: 5000,
            })
          )
        );
    },
  },
};
</script>

<script>
</script>