import SignUp from "./components/SignUp.vue";
import LoginVue from "./components/Login.vue";
import OTP from "./components/OTP.vue";
import HomeVue from "./components/Home.vue";
import OnBoarding from "./components/OnBoarding.vue";
import resetPassword from "./components/resetPassword.vue";
import identityUpload from "./components/identityUpload.vue";
import changePassword from "./components/changePassword.vue";
import ProfileVue from "./components/Profile.vue";
import decisionVue from "./components/Decision.vue";

import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    name: "SignUp",
    component: SignUp,
    path: "/signup",
  },
  {
    name: "Login",
    component: LoginVue,
    path: "/",
  },
  {
    name: "OTP",
    component: OTP,
    path: "/verify-otp",
  },
  {
    name: "OnBoarding",
    component: OnBoarding,
    path: "/onboarding",
  },
  {
    name: "Home",
    component: HomeVue,
    path: "/home",
    meta: {
      title: "Dashboard",
    },
  },
  {
    name: "resetPassword",
    component: resetPassword,
    path: "/reset-password",
    meta: {
      title: "Reset Password",
    },
  },
  {
    name: "changePassword",
    component: changePassword,
    path: "/change-password",
    meta: {
      title: "Change Password",
    },
  },
  {
    name: "identityUpload",
    component: identityUpload,
    path: "/upload-document",
    meta: {
      title: "Upload Idntity",
    },
  },
  {
    name: "decisionVue",
    component: decisionVue,
    path: "/decision",
    meta: {
      title: "Decision",
    },
  },
  {
    name: "ProfileVue",
    component: ProfileVue,
    path: "/profile",
    meta: {
      title: "Profile",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
