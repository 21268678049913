<template>
  <div class="container-fluid page-body-wrapper full-page-wrapper">
    <div class="content-wrapper d-flex align-items-center auth">
      <div class="row flex-grow">
        <div class="col-lg-4 mx-auto">
          <div class="auth-form-light text-left p-5">
            <div class="brand-logo">
              <img src="" />
              <h1>MCard</h1>
            </div>

            <h3 class="font-weight-light">
              Please Wait while we create your card.
            </h3>
            <form ref="signupForm" class="pt-3" @submit.prevent="onSubmit">
              <div class="mt-3">
                <div
                  v-if="loading"
                  class="spinner-border spinner-border-lg"
                ></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "decisionVue",

  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    this.loading = true;
    setTimeout(() => this.$router.push({ name: "Home" }), 3000);
    // this.loading = false;
    // this.$router.push({ name: "Home" });
  },
};
</script>