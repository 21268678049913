<template>
  <div class="container-scroller">
    <nav-bar></nav-bar>
    <div class="container-fluid page-body-wrapper">
      <side-bar></side-bar>
      <div class="main-panel">
        <div class="content-wrapper">
          <div class="row">
            <div class="col-md-4 stretch-card grid-margin">
              <div class="card bg-gradient-danger card-img-holder text-white">
                <div class="card-body">
                  <h4 class="font-weight-normal mb-3">
                    MCard
                    <i class="mdi mdi-chart-line mdi-24px float-right"></i>
                  </h4>
                  <h2 class="mb-5">1234 1234 1234 1234</h2>
                  <h6 class="card-text">{{ this.user_name }}</h6>
                </div>
              </div>
            </div>
          </div>
          <h4>Recent Transactions</h4>
          <div class="row">
            <div class="col-12 grid-margin">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Transaction Id</th>
                          <th>Transaction Type</th>
                          <th>Description</th>
                          <th>Amount</th>
                          <th>Transaction Date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>MC1234567890</td>
                          <td>DR</td>
                          <td>AOne Gas</td>
                          <td>$10</td>
                          <td>Apr 5, 2024</td>
                          <td>
                            <label class="badge badge-gradient-success"
                              >PROCESSED</label
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>MC1234567890</td>
                          <td>DR</td>
                          <td>AOne Gas</td>
                          <td>$10</td>
                          <td>Apr 5, 2024</td>
                          <td>
                            <label class="badge badge-gradient-warning"
                              >PROCESSING</label
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>MC1234567890</td>
                          <td>DR</td>
                          <td>AOne Gas</td>
                          <td>$10</td>
                          <td>Apr 5, 2024</td>
                          <td>
                            <label class="badge badge-gradient-danger"
                              >DECLINED</label
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>MC1234567890</td>
                          <td>DR</td>
                          <td>Steam Games</td>
                          <td>$15</td>
                          <td>Apr 2, 2024</td>
                          <td>
                            <label class="badge badge-gradient-success"
                              >Complete</label
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeVue",
  NavBarounted() {
    let user = localStorage.getItem("user-info");
    let user_data = JSON.parse(user);
    console.log(user_data["user_id"]);
    console.log(user_data["first_name"] + user_data["last_name"]);
    if (!user) {
      this.$router.push({ name: "LoginVue" });
    }
    return { user_name: user_data["first_name"] + user_data["last_name"] };
  },
};
</script>