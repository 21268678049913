<template>
  <div class="container-fluid page-body-wrapper full-page-wrapper">
    <div class="content-wrapper d-flex align-items-center auth">
      <div class="row flex-grow">
        <div class="col-lg-4 mx-auto">
          <div class="auth-form-light text-left p-5">
            <div class="brand-logo">
              <img src="" />
              <h1>MCard</h1>
            </div>
            <h4>Hello! let's get started</h4>
            <h6 class="font-weight-light">Login to continue.</h6>
            <form id="form" class="pt-3" @submit.prevent="onSubmit">
              <div class="form-group">
                <input
                  type="email"
                  class="form-control form-control-lg"
                  id="email"
                  placeholder="Enter Email"
                  v-model="email"
                  required
                />
              </div>
              <div class="form-group">
                <input
                  type="password"
                  class="form-control form-control-lg"
                  id="password"
                  placeholder="Enter password"
                  v-model="password"
                  required
                />
              </div>
              <div class="mt-3">
                <button
                  class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"
                  v-on:click="login"
                  :disabled="loading"
                >
                  <div
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                  ></div>
                  <span v-if="loading">Signing In</span>
                  <span v-else>Sign In</span>
                </button>
              </div>
              <div
                class="my-2 d-flex justify-content-between align-items-center"
              >
                <div class="form-check"></div>
                <a
                  class="auth-link text-black"
                  @click="$router.push('reset-password')"
                  >Forgot password?</a
                >
              </div>

              <div class="text-center mt-4 font-weight-light">
                Don't have an account?
                <a class="text-primary" @click="$router.push('signup')"
                  >Create</a
                >
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import { HTTP } from "../http-common";
const toast = useToast();
export default {
  name: "LoginVue",
  data() {
    return {
      email: "",
      password: "",
      loading: false,
    };
  },
  methods: {
    async login() {
      this.loading = true;
      const postData = {
        email: this.email,
        password: this.password,
      };
      const headers = {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      };
      await HTTP.post("auth/login", postData, headers)
        .then(
          (response) => (
            (this.loading = false),
            console.log(response.data.message),
            toast.success(response.data.message, {
              timeout: 2000,
            }),
            localStorage.setItem(
              "user-info",
              JSON.stringify(response.data.data)
            ),
            this.$router.push({ name: "Home" })
          )
        )
        .catch(
          (error) => (
            (this.loading = false),
            console.log(error),
            console.log(error.response.data.message),
            // or with options
            toast.error(error.response.data.message, {
              timeout: 5000,
            })
          )
        );
    },
  },
};
</script>