 <template>
  <nav class="sidebar sidebar-offcanvas" id="sidebar">
    <ul class="nav">
      <li class="nav-item">
        <a class="nav-link" href="/#/home">
          <span class="menu-title">Dashboard</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/#/profile">
          <span class="menu-title">Profile</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "SideBar",
  data() {
    let user = localStorage.getItem("user-info");
    let user_data = JSON.parse(user);
    return { user_email: user_data["email"] };
  },
};
</script>