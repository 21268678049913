<template>
  <div class="container-fluid page-body-wrapper full-page-wrapper">
    <div class="content-wrapper d-flex align-items-center auth">
      <div class="row flex-grow">
        <div class="col-lg-4 mx-auto">
          <div class="auth-form-light text-left p-5">
            <div class="brand-logo">
              <img src="" />
              <h1>MCard</h1>
            </div>
            <h4>Please Provide more infomation to continue</h4>

            <form
              ref="onBoardingForm"
              id="form"
              class="pt-3"
              @submit.prevent="onSubmit"
            >
              <div class="form-group">
                <input
                  class="form-control form-control-lg"
                  type="text"
                  id="ssn"
                  placeholder="Enter ssn number"
                  v-model="ssn"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-lg"
                  type="date"
                  id="dob"
                  placeholder="Enter date of birth"
                  v-model="dob"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-lg"
                  type="text"
                  id="line_1"
                  placeholder="Enter Address Line 1"
                  v-model="line_1"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-lg"
                  type="text"
                  id="line_2"
                  placeholder="Enter Address Line 2"
                  v-model="line_2"
                />
              </div>

              <div class="form-group">
                <input
                  class="form-control form-control-lg"
                  type="text"
                  id="city"
                  placeholder="Enter city"
                  v-model="city"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-lg"
                  type="text"
                  id="state"
                  placeholder="Enter state"
                  v-model="state"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-lg"
                  type="text"
                  id="state"
                  placeholder="Enter zipcode"
                  v-model="zipcode"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-lg"
                  type="text"
                  id="country"
                  placeholder="Enter country"
                  v-model="country"
                />
              </div>
              <div class="mt-3">
                <button
                  class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"
                  v-on:click="OnBoarding"
                  :disabled="loading"
                >
                  <div
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                  ></div>
                  <span v-if="loading">Saving</span>
                  <span v-else>Save</span>
                </button>
              </div>
              <div
                class="my-2 d-flex justify-content-between align-items-center"
              ></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import { HTTP } from "../http-common";
const toast = useToast();
export default {
  name: "OnBoarding",
  data() {
    return {
      ssn: "",
      dob: "",
      line_1: "",
      line_2: "",
      city: "",
      state: "",
      zipcode: "",
      country: "",
      loading: false,
    };
  },
  methods: {
    async OnBoarding() {
      this.loading = true;
      let user = localStorage.getItem("user-info");
      let user_data = JSON.parse(user);

      console.log(user_data["user_id"]);
      const postData = {
        user_id: user_data["user_id"],
        ssn: this.ssn,
        dob: this.dob,
        line_1: this.line_1,
        line_2: this.line_2,
        city: this.city,
        state: this.state,
        zipcode: this.zipcode,
        country: this.country,
      };
      const headers = {
        headers: { "Content-Type": "application/json; charset=UTF-8" },
      };
      await HTTP.post("auth/onboarding", postData, headers)
        .then(
          (response) => (
            (this.loading = false),
            console.log(response.data.message),
            toast.success(response.data.message, {
              timeout: 2000,
            }),
            this.$router.push({ name: "identityUpload" })
          )
        )
        .catch(
          (error) => (
            (this.loading = false),
            console.log(error),
            console.log(error.response.data.message),
            // or with options
            toast.error(error.response.data.message, {
              timeout: 5000,
            }),
            this.$refs.onBoardingForm.reset()
          )
        );
    },
  },
};
</script>