<template>
  <div class="container-fluid page-body-wrapper full-page-wrapper">
    <div class="content-wrapper d-flex align-items-center auth">
      <div class="row flex-grow">
        <div class="col-lg-4 mx-auto">
          <div class="auth-form-light text-left p-5">
            <div class="brand-logo">
              <img src="" />
              <h1>MCard</h1>
            </div>
            <h4>Hello! let's get started</h4>
            <h6 class="font-weight-light">Sign up to continue.</h6>
<p v-if="errors.length">
          <b>Please correct the following error(s):</b>
          <ul>
            <li v-for="error in errors" :key="error" style="color:red">{{ error }}</li>
          </ul>
        </p>  
            <form ref="signupForm" class="pt-3" @submit.prevent="onSubmit">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-lg"
                  id="first_name"
                  placeholder="Enter First Name"
                  v-model="first_name"
                />
              </div>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-lg"
                  id="last_name"
                  placeholder="Enter Last Name"
                  v-model="last_name"
                />
              </div>
              <div class="form-group">
                <input
                  type="tel"
                  class="form-control form-control-lg"
                  id="phone"
                  placeholder="Enter Phone Number"
                  v-model="phone"
                />
              </div>
              <div class="form-group">
                <input
                  type="email"
                  class="form-control form-control-lg"
                  id="email"
                  placeholder="Enter Email"
                  v-model="email"
                />
              </div>
              <div class="form-group">
                <input
                  type="password"
                  class="form-control form-control-lg"
                  id="password"
                  placeholder="Enter password"
                  v-model="password"
                />
              </div>
              <div class="mt-3">
                
                <button
                  class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"
                  v-on:click="signUp"
                  :disabled="loading"
                >
                  <div
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                  ></div>
                  <span v-if="loading">Please Wait</span>
                  <span v-else>Sign Up</span>
                  
                </button>
              </div>
              <div
                class="my-2 d-flex justify-content-between align-items-center"
              >
                <div class="form-check"></div>
                <a
                  class="auth-link text-black"
                  @click="$router.push('reset-password')"
                  >Forgot password?</a
                >
              </div>

              <div class="text-center mt-4 font-weight-light">
                Already have an account?
                <a class="text-primary" @click="$router.push('/')">Login</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import { HTTP } from "../http-common";
const toast = useToast();
export default {
  name: "SignUp",
  data() {
    return {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      password: "",
      errors: [],
      loading: false,
    };
  },
  methods: {
    async signUp() {
      this.errors = [];
      this.loading = true;
      if (
        this.email &&
        this.first_name &&
        this.last_name &&
        this.password &&
        this.phone
      ) {
        const postData = {
          email: this.email,
          first_name: this.first_name,
          last_name: this.last_name,
          password: this.password,
          phone_number: this.phone,
        };
        const headers = {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
          },
        };
        await HTTP.post("auth/signup", postData, headers)
          .then(
            (response) => (
              (this.loading = false),
              console.log(response.data.message),
              toast.success(response.data.message, {
                timeout: 2000,
              }),
              localStorage.setItem(
                "user-info",
                JSON.stringify(response.data.data)
              ),
              this.$router.push({ name: "OTP" })
            )
          )
          .catch(
            (error) => (
              (this.loading = false),
              console.log(error),
              console.log(error.response.data.message),
              // or with options
              toast.error(error.response.data.message, {
                timeout: 5000,
              }),
              this.$refs.signupForm.reset()
            )
          );
      } else {
        if (!this.email) this.errors.push("Email required.");
        if (!this.first_name) this.errors.push("First Name required.");
        if (!this.last_name) this.errors.push("last Name required.");
        if (!this.password) this.errors.push("Password required.");
        if (!this.phone) this.errors.push("Phone required.");
        this.loading = false;
      }
    },
  },
};
</script>