<template>
  <div class="container-scroller">
    <nav-bar></nav-bar>
    <div class="container-fluid page-body-wrapper">
      <side-bar></side-bar>
      <div class="main-panel">
        <div class="content-wrapper">
          <div class="page-header">
            <h3 class="page-title">Profile</h3>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="/#/home">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">
                  Profile
                </li>
              </ol>
            </nav>
          </div>

          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <form class="form-sample">
                  <h3 class="card-description" style="">Personal Info</h3>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label"
                          >First Name</label
                        >
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            :value="user_profile_data?.user_info?.first_name"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Last Name</label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            :value="user_profile_data?.user_info?.last_name"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Email</label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            :value="user_profile_data?.user_info?.email"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Phone</label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            :value="user_profile_data?.user_info?.phone"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label"
                          >Date Of Birth</label
                        >
                        <div class="col-sm-9">
                          <input
                            class="form-control"
                            placeholder="dd/mm/yyyy"
                            :value="user_profile_data?.user_kyc_info?.dob"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <h3 class="card-description">Address</h3>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Line 1</label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            :value="user_profile_data?.user_kyc_info?.line_1"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Line 2</label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            :value="user_profile_data?.user_kyc_info?.line_2"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">City</label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            :value="user_profile_data?.user_kyc_info?.city"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">State</label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            :value="user_profile_data?.user_kyc_info?.state"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Zipcode</label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            :value="user_profile_data?.user_kyc_info?.zipcode"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Country</label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            :value="user_profile_data?.user_kyc_info?.country"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <h3 class="card-description">Documents</h3>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Document</label>
                        <div class="col-sm-9">
                          <a
                            :href="user_profile_data?.user_documents?.document"
                            target="_blank"
                            >Download</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "./SideBar.vue";
import { useToast } from "vue-toastification";
import { HTTP } from "../http-common";
const toast = useToast();
export default {
  components: { SideBar },
  name: "ProfileVue",
  data() {
    return {
      user_profile_data: null,
    };
  },
  mounted() {
    let user = localStorage.getItem("user-info");
    if (!user) {
      this.$router.push({ name: "LoginVue" });
    }
    let user_data = JSON.parse(user);
    let user_id = user_data["user_id"];
    const headers = {
      headers: { "Content-Type": "application/json; charset=UTF-8" },
    };
    HTTP.get("auth/user/" + user_id, headers)
      .then((response) => (this.user_profile_data = response.data.data))
      .catch(
        (error) => (
          (this.loading = false),
          console.log(error),
          console.log(error.response.data.message),
          // or with options
          toast.error(error.response.data.message, {
            timeout: 5000,
          })
        )
      );
  },
};
</script>