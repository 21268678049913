<template>
  <div class="container-fluid page-body-wrapper full-page-wrapper">
    <div class="content-wrapper d-flex align-items-center auth">
      <div class="row flex-grow">
        <div class="col-lg-4 mx-auto">
          <div class="auth-form-light text-left p-5">
            <div class="brand-logo">
              <img src="" />
              <h1>MCard</h1>
            </div>
            <h4>One More Step</h4>
            <h6 class="font-weight-light">
              Please Upload a clear picture of your driving licence.
            </h6>
          <p v-if="extracted_data">
              <b>Extracted Information from Document</b>
              <ul>
                <table>
                  <tr v-for="value in extracted_data" :key="value">
                  <td>{{ value.name }}</td>
                  <td>{{ value.value }}</td>
                  <td>{{ value.status }}</td>
                  </tr>
                </table>
              </ul>
            </p>  
            <form ref="signupForm" class="pt-3" @submit.prevent="onSubmit">
              <div class="form-group">
                <input
                  class="form-control form-control-lg"
                  type="file"
                  accept="image/*"
                  id="file-input"
                  @change="uploadImage($event)"
                />
              </div>
              <div class="mt-3">
                
                <button
                  class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"
                  @click="$router.push('decision')"
                  :disabled="loading"
                >
                  <div
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                  ></div>
                  <span v-if="loading">Processing File</span>
                  <span v-else>Upload and Validate</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import { HTTP } from "../http-common";
const toast = useToast();

export default {
  name: "identityUpload",

  data() {
    return {
      verification_data: "",
      extracted_data: "",
      loading: false,
    };
  },
  methods: {
    uploadImage(event) {
      this.loading = true;
      let user = localStorage.getItem("user-info");
      let user_data = JSON.parse(user);

      console.log(user_data["user_id"]);
      let user_id = user_data["user_id"];
      let data = new FormData();
      // data.append("user_id", "my-picture");
      // data.append("photo_id_key", "my-picture");
      data.append("photo_id", event.target.files[0]);

      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };

      HTTP.post(
        "auth/upload-document?user_id=" +
          user_id +
          "&photo_id_key=PHOTO_ID_FRONT",
        data,
        config
      )
        .then((response) => {
          console.log("image upload response > ", response);
          this.errors = [];
          const headers = {
            headers: { "Content-Type": "application/json; charset=UTF-8" },
          };
          HTTP.get("auth/verify-documents?user_id=" + user_id, headers)
            .then(
              (response) => (
                (this.loading = false),
                console.log(response.data.message),
                console.log(response.data.verification_data),
                console.log(response.data.data),
                (this.extracted_data = response.data.data),
                (this.verification_data = response.data.verification_data),
                toast.success(response.data.message, {
                  timeout: 2000,
                })
                // this.$router.push({ name: "Home" })
              )
            )
            .catch(
              (error) => (
                (this.loading = false),
                console.log(error),
                console.log(error.response.data.message),
                // or with options
                toast.error(error.response.data.message, {
                  timeout: 5000,
                }),
                this.$refs.signupForm.reset()
              )
            );
        })
        .catch(
          (error) => (
            (this.loading = false),
            console.log(error),
            console.log(error.response.data.message),
            // or with options
            toast.error(error.response.data.message, {
              timeout: 5000,
            }),
            this.$refs.signupForm.reset()
          )
        );
    },
    async validateDocument() {
      this.errors = [];
      const headers = {
        headers: { "Content-Type": "application/json; charset=UTF-8" },
      };
      await HTTP.get("verify-documents?user_id=123", headers)
        .then(
          (response) => (
            console.log(response.data.message),
            console.log(response.data.verification_data),
            console.log(response.data.data),
            (this.extracted_data = response.data.data),
            (this.verification_data = response.data.verification_data),
            toast.success(response.data.message, {
              timeout: 2000,
            })
            // this.$router.push({ name: "Home" })
          )
        )
        .catch(
          (error) => (
            console.log(error),
            console.log(error.response.data.message),
            // or with options
            toast.error(error.response.data.message, {
              timeout: 5000,
            }),
            this.$refs.signupForm.reset()
          )
        );
    },
  },
};
</script>