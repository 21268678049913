import { createApp } from "vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "./assets/style.css";
import "./assets/material.css";
import App from "./App.vue";
import router from "./router";
import NavBar from "./components/NavBar.vue";
import SideBar from "./components/SideBar.vue";
// const cors = require("cors");
// const corsConfig = {
//   credentials: true,
//   origin: true,
// };

const app = createApp(App);
app.component("nav-bar", NavBar);
app.component("side-bar", SideBar);
app.use(Toast);
app.use(router);
// app.use(cors({ origin: true }));
app.mount("#app");
